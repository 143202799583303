import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Space, Button, Modal, Switch } from 'antd';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import NotificationsDropdownMenu from '../Notifications/List/NotificationsDropdownMenu';
import { useHasContributorAgreedToLatestAgreement } from '../service-agreement/query/useGetHasSignedToLatestAgreement';
import { ServiceAgreementModal } from '../service-agreement/components/modal';
import AuthContext from '../../contexts/AuthContext';
import { ROOT_PATH } from '../../routes';
import {
	getUserPref,
	isLegacyModeEnabled,
} from '../Common/ApplicationModeChanger';

import './navbar.scss';
import ContributorMenu from './UsersMenus/ContributorMenu';
import PmMenu from './UsersMenus/PmMenu';
import ContributorMobileMenu from './UsersMenus/ContributorMobileMenu';

const XSRF_TOKEN_REFRESH_PERIOD = 1000 * 60 * 30; //30 minutes

const BannerContainer = styled.div`
	background-color: rgb(127 29 29);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const BannerText = styled.span`
	color: white;
	user-select: none;
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
		'Liberation Mono', 'Courier New', monospace;
	opacity: 0.75;
`;

function WelcomeMessage() {
	const { user, getLoggedUser } = useContext(AuthContext);

	const isFirstTimeToLogin = !Boolean(user?.lastLogin);
	const isContributor = Boolean(user?.role !== 'pm');

	const [isModalOpen, setIsModalOpen] = useState(isFirstTimeToLogin);

	const handleOnDismiss = () => {
		setIsModalOpen(false);
		getLoggedUser();
	};

	return (
		isContributor && (
			<Modal
				visible={isModalOpen}
				footer={[
					<Button type="primary" onClick={handleOnDismiss}>
						Dismiss
					</Button>,
				]}
				closable={false}
			>
				<h2>Congratulations!</h2>

				<p>Your account has been successfully created.</p>
				<p>
					You’re now just a few steps away from unlocking the opportunity to
					work on exciting projects and start earning money.
				</p>
			</Modal>
		)
	);
}

function ServiceAgreementBanner({ user }) {
	const userId = user?.id;
	const isPM = user?.role === 'pm';

	const { hasContributorSignedLatestAgreement } =
		useHasContributorAgreedToLatestAgreement({
			userId,
			isPM,
		});

	const [showAgreementModal, setShowAgreementModal] = useState(
		hasContributorSignedLatestAgreement === false,
	);
	const [showAgreementNotice, setShowAgreementNotice] = useState(
		hasContributorSignedLatestAgreement === false,
	);

	useEffect(() => {
		setShowAgreementNotice(hasContributorSignedLatestAgreement === false);
		setShowAgreementModal(hasContributorSignedLatestAgreement === false);
	}, [hasContributorSignedLatestAgreement]);

	const onModalClose = useCallback((agreed) => {
		setShowAgreementModal(false);
		setShowAgreementNotice(!agreed);
	}, []);

	const viewModal = useCallback(() => {
		setShowAgreementModal(true);
	}, []);

	if (!userId || isPM) {
		return null;
	}

	return (
		<>
			{showAgreementNotice && (
				<BannerContainer>
					<BannerText>You have unsigned Master Service Agreement</BannerText>
					<Button
						onClick={viewModal}
						style={{ marginLeft: '1.5rem' }}
						type="primary"
					>
						View Master Service Agreement
					</Button>
				</BannerContainer>
			)}
			<ServiceAgreementModal
				visible={showAgreementModal}
				onClose={onModalClose}
			/>
		</>
	);
}

const Navbar = ({ isLegacyMode, setIsLegacyMode }) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const {
		isRefreshingCookies,
		user,
		initializeAuth,
		getLoggedUser,
		logout,
		refreshXsrfToken,
	} = useContext(AuthContext);

	const isNotFirstTimeToLogin = Boolean(user?.lastLogin);
	const isUserLoggedin = Boolean(user?.id);

	//Initializing the authentication context.
	useEffect(() => {
		initializeAuth() //Initializing axios with error interceptors.
			.then(() => refreshXsrfToken(true)) //Refreshing the xsrf token before sending any call to the backend.
			.then(() => getLoggedUser()); //Getting the logged in user based on the cookies that may be saved in the browser.

		//Creating the refresh xsrf token process which is triggered every 30 minutes.
		let xsrfRefreshID = setInterval(
			refreshXsrfToken,
			XSRF_TOKEN_REFRESH_PERIOD,
		);

		//Adding the clear interval method when the component unmounts.
		return () => {
			clearInterval(xsrfRefreshID);
		};

		//eslint-disable-next-line
	}, []);

	function handleOnToggleChange(checked) {
		const currentPreference = getUserPref();

		const updatedPref = {
			...currentPreference,
			state: { ...currentPreference.state, isLegacyUI: checked },
		};
		localStorage.setItem('ldp-user-pref', JSON.stringify(updatedPref));

		setIsLegacyMode(isLegacyModeEnabled());
	}

	return (
		<>
			{isUserLoggedin && isNotFirstTimeToLogin && (
				<ServiceAgreementBanner user={user} key={user?.id} />
			)}

			{isUserLoggedin && !isNotFirstTimeToLogin && <WelcomeMessage />}

			<div className="nav-container">
				{/* The logo */}
				<Space>
					<Link to={ROOT_PATH}>
						<img
							className="nav-logo"
							src={process.env.PUBLIC_URL + '/images/ls_logo.svg'}
							alt="logo"
						/>
						<span className="nav-logo-text">LDP</span>
					</Link>
				</Space>

				{/* User Links */}
				{user && (
					<div className="nav-links-container">
						{/* Menu */}
						<Switch
							defaultChecked={isLegacyModeEnabled()}
							checkedChildren="Legacy"
							unCheckedChildren="New"
							onChange={handleOnToggleChange}
						/>
						{user.role === 'pm' && (
							<PmMenu
								user={user}
								isRefreshingCookies={isRefreshingCookies}
								logout={logout}
							/>
						)}
						{user.role === 'contributor' && !isTabletOrMobile && (
							<ContributorMenu
								isRefreshingCookies={isRefreshingCookies}
								logout={logout}
								user={user}
							/>
						)}
						{user.role === 'contributor' && isTabletOrMobile && (
							<ContributorMobileMenu
								isRefreshingCookies={isRefreshingCookies}
								logout={logout}
								user={user}
							/>
						)}

						{/* The notifications button */}
						{user.role === 'contributor' && <NotificationsDropdownMenu />}
					</div>
				)}
			</div>
		</>
	);
};

export default Navbar;
