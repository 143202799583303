export function getUserPref() {
	const defaultPref = {
		state: { isLegacyUI: true },
	};

	const storedPref = localStorage.getItem('ldp-user-pref');
	if (storedPref) {
		const parsedPref = JSON.parse(storedPref);

		if (parsedPref.state?.isLegacyUI === undefined) {
			parsedPref.state.isLegacyUI = defaultPref.state.isLegacyUI;
			localStorage.setItem('ldp-user-pref', JSON.stringify(parsedPref));
		}

		return parsedPref;
	} else {
		localStorage.setItem('ldp-user-pref', JSON.stringify(defaultPref));
		return defaultPref.state;
	}
}

export function isLegacyModeEnabled() {
	const userPreference = getUserPref();
	return userPreference?.state?.isLegacyUI;
}
